import XiaoJinBaoImage from './../images/home/xiaojinbao.webp';
import XiaoJinDouImage from './../images/home/xiaojindou.webp';
import XiaoJinDanImage from './../images/home/xiaojindan.webp';
import XiaoJinDunImage from './../images/home/xiaojindun.webp';
import HongRenImage from './../images/home/hongren.webp';

import BigXiaoJinBaoImage from './../images/detail/bigxiaojinbao.webp';
import BigXiaoJinDouImage from './../images/detail/bigxiaojindou.webp';
import BigXiaoJinDanImage from './../images/detail/bigxiaojindan.webp';
import BigXiaoJinDunImage from './../images/detail/bigxiaojindun.webp';
import BigHongRenImage from './../images/detail/bighonglian.webp';
export const productInfomation = [{
    name: '小金宝睡前面膜',
    title1: '针对熬夜垮',
    title2: '夜间提拉SPA',
    id: '1714406019177',
    url: XiaoJinBaoImage,
    bigUrl: BigXiaoJinBaoImage,
    tmallUrl: 'https://detail.tmall.com/item.htm?id=771877759117&spm=a21dvs.23580594.0.0.52de645e8iTcrP',
    price: '798元',
    size: '50g',
    oneBuySelling: '专为熟龄熬夜肌垮脸问题打造 拒绝熬夜垮脸，一敷提拉·焕亮·嫩肤',
    introduce: '免洗设计，睡前使用，沉浸式享受整夜提拉SPA。晨起焕然一新，解锁透亮光感的同时，见证嘭弹苹果肌、上扬下颌线，从此熬夜无负担。',
    useage: [
        `1. 夜间焕肤（28天焕亮嫩肤）：每天1次，夜间睡前使用，取硬币大小薄涂全脸，约1g/次，针对局部细纹加厚涂抹，免洗入睡。`,
        `2. 紧致提拉（15分钟挑战小脸上扬）：每周2-3次，取5-6g涂抹面膜至遮盖住肤色，每次约5-6g左右，厚敷15-20min后清水洗去。`
    ]
}, {
    name: '小金斗随身精华',
    title1: '针对熬夜塌',
    title2: '肌底充盈蓄能',
    id: '1714406031681',
    url: XiaoJinDouImage,
    bigUrl: BigXiaoJinDouImage,
    tmallUrl: 'https://detail.tmall.com/item.htm?id=771944147308&spm=a21dvs.23580594.0.0.52de645e8iTcrP&skuId=5285176996594',
    price: '699元',
    size: '30ml',
    oneBuySelling: '专为熟龄熬夜肌凹陷失弹问题打造 打底精华，见证双倍功效',
    introduce: '随身可用，方便携带，基底促渗，层层赋活充盈肌底。每日作为打底精华，叠加日常护肤使用，实现基底促胶原，嘭弹不显纹。',
    useage: [
        `1. 每天早晚各1次，按压挤出使用2-3泵精华（约0.3ml）于掌心，均匀涂抹至面部，后续再叠加水乳等产品使用。`,
    ]
}, {
    name: '小金弹救急次抛',
    title1: '针对熬夜损',
    title2: '屏损救急修护',
    id: '1714406041056',
    url: XiaoJinDanImage,
    bigUrl: BigXiaoJinDanImage,
    tmallUrl: 'https://detail.tmall.com/item.htm?id=771946571498&spm=a21dvs.23580594.0.0.52de645e8iTcrP&skuId=5288642869571',
    price: '499元',
    size: '3ml*5支',
    oneBuySelling: '专为熟龄熬夜肌屏障损伤问题打造 一修「红」二修「塌」，强韧肌肤丰盈匀净',
    introduce: '一支紧急救场脆弱疲惫肌，潜移默化修护肌肤，为肌肤撑起保护伞的同时，焕活嘭弹，打造稳稳水光肌。',
    useage: [
        `1. 日常维稳嘭弹：每天1次，0.5ml/次，全脸按压涂抹，1盒可以使用1个月左右。`,
        `2. 局部点涂救急：每次1刻度，约0.2ml左右，用指腹轻轻点涂在不适区域。 `,
        `3. 救场密集护理：每天1次，每次1ml，全脸按压涂抹，连续使用7天。`,
        `4. 叠加底妆服帖：每次1刻度，约0.2ml左右，滴在粉底中，搅匀后将粉底液涂抹上脸`,
    ]
}, {
    name: '小金盾全场景面霜',
    title1: '针对熬夜纹',
    title2: '早晚抚纹紧塑',
    id: '1714406049659',
    url: XiaoJinDunImage,
    bigUrl: BigXiaoJinDunImage,
    tmallUrl: 'https://detail.tmall.com/item.htm?id=771936447741&spm=a21dvs.23580594.0.0.52de645e8iTcrP&skuId=5288595157952',
    price: '798元',
    size: '50g',
    oneBuySelling: '专为熟龄熬夜肌皱纹问题打造 24H「霜」倍养护 淡纹紧轮廓',
    introduce: '护肤最后一道工序，仿若为面部打造一层天然护盾，24H呵护，让肌肤无惧早晚冷热温差。添加双重玻色因复配经典多肽，全脸细腻平滑，无惧皱纹。',
    useage: [
        `1. 每天早晚使用，取硬币大小涂抹全脸，均匀涂抹至吸收，约1g/次。`,
    ]
}, {
    name: '红人滤镜面膜',
    title1: '针对熬夜暗',
    title2: '即刻焕嫩亮肤',
    id: '1714406060297',
    url: HongRenImage,
    bigUrl: BigHongRenImage,
    tmallUrl: 'https://detail.tmall.com/item.htm?id=778418029029&spm=a21dvs.23580594.0.0.52de645e8iTcrP',
    price: '199元',
    size: '30ml*5片',
    oneBuySelling: '专为熟龄熬夜肌暗沉问题打造 三层滤镜，拯救熬夜素颜脸',
    introduce: '15分钟层层递进，饱满微乳营养质地，用1次嫩一次，即刻丰盈焕亮，柔嫩匀净。搭配5D锁水植物膜布，有效补水的同时，舒缓修红，紧致提升，仿若给肌肤开了滤镜。',
    useage: [
        `1. 日常嘭弹抗皱：每周2-3次，晚上洁面后，取1片面膜敷15-20min，取下面膜后用指腹轻轻按摩吸收。`,
        `2. 熬夜救急焕亮/妆前补水焕亮：早上洁面后，取1片面膜敷15-20min，清水洗去，叠加基础护肤水后上妆。`,
    ]
}]